export enum BaseButtonType {
  "FILL",
  "OUTLINE",
}
export enum IconButtonType {
  INFO,
  DELETE,
  EDIT,
}
export enum DeviceIdType {
  SERIAL_NUMBER = "serial_number",
  IMEI_NUMBER = "imei_number",
}
export interface BaseButtonProps {
  type: BaseButtonType;
  onClick: () => void;
  title: string;
  disabled?: boolean;
}
export interface ButtonGroupPops {
  buttons: BaseButtonProps[];
}
export interface BaseModalProps {
  title: string;
}
export interface BaseInputTextProps {
  id: string;
  label?: string;
  placeholder?: string;
  error?: string;
  modelValue: any;
  readonly?: boolean;
}
export interface BaseSelectInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  error?: string;
  modelValue: any;
  colors: boolean;
  options: {
    label: string;
    value: string;
  }[];
}
export interface BaseColorInputProps {
  id: string;
  label: string;
  placeholder: string;
  error?: string;
  modelValue: any;
  colors: boolean;
  options: {
    label: string;
    value: string;
  }[];
}
export interface BaseOrganizationSelectInputProps {
  modelValue: any;
  options: {
    id: number;
    name: string;
    logo: string;
  }[];
}

export type OrganizationType = {
  id: number;
  name: string;
  logo: string;
} | null;

export type IconButtonProps = {
  type: IconButtonType;
  onClick: () => void;
  disabled?: boolean;
};
export type ErrorResponseType = {
  response: {
    data: {
      status?: {
        code: number;
        message: string;
      };
      bad_data?: any;
      [key: string]: any;
    };
  };
};

export interface FormValues {
  serialNumber: string;
  imeiId: string;
  mdmType: string;
  [key: string]: any; // Allow for other form fields
}

export interface DeviceRow {
  deviceID?: string;
  serialNumber?: string;
  imeiNumber?: string;
  deviceIDType?: string;
  assetNumber?: string;
  productPackageName?: string;
  location?: string;
  employee?: string;
  role?: string;
  color?: string;
  telephoneNumber?: string;
  mobileNumber?: string;
  simPin?: string;
  devicePin?: string;
  comment?: string;
  error?: boolean;
  [key: string]: any; 
}
